<template>
  <div class="d-flex justify-content-start">
    <!-- {{ duration }} -->
    {{ params.data.duration }}
  </div>
</template>

<script>

export default {
  name: 'CellRendererLink',
  data() {
    return {
      duration: '', 
    }
  },
  computed: {
    url() {
      return `/help-desks/issues/${this.params.data._id}`
    },
  },
  mounted() {
    this.countDuration()
  },
  methods: {
    countDuration() {
      const diff = this.params.data.duration

      const days = Math.floor(diff / 60 / 60 / 24)
      let hours = Math.floor(diff / 60 / 60)
      let minutes = Math.floor((diff - (hours * 60 * 60)) / 60)
      let seconds = Math.floor((diff - (hours * 60 * 60) - (minutes * 60)))
      // this.duration = `${hours}h ${minutes}m ${seconds}s`

      // if (hours   < 10) {hours   = "0"+hours;}
      // if (minutes < 10) {minutes = "0"+minutes;}
      // if (seconds < 10) {seconds = "0"+seconds;}
      this.duration = `${hours}h ${minutes}m ${seconds}s`

      // const created_at = new Date(this.params.data.created_at)
      // const now = new Date()
      // const diff = now - created_at
      // const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      // const hours = Math.floor(diff / (1000 * 60 * 60))
      // const minutes = Math.floor((diff - (hours * 1000 * 60 * 60))/ (1000 * 60))
      // this.duration = `${hours}h ${minutes}m`
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

a:hover {
  color: $primary  !important;
}
</style>