/* eslint-disable import/prefer-default-export */

export const helpDesksMixins = {
  computed: {
    statusBadgeColor() {
      return value => {
        if (value === 'open') return 'success'
        if (value === 'closed') return 'danger'
        if (value === 'urgent') return 'warning'
        return 'primary'
      }
    },
  },
  methods: {
    countDuration(durationInSeconds) {
      const diff = durationInSeconds

      // const days = Math.floor(diff / 60 / 60 / 24)
      // const hours = Math.floor(diff / 60 / 60)
      // const minutes = Math.floor((diff - (hours * 60 * 60)) / 60)
      // const seconds = Math.floor((diff - (hours * 60 * 60) - (minutes * 60)))
      // return `${hours}h ${minutes}m ${seconds}s`

      const sec_num = parseInt(diff, 10); // don't forget the second param
      let hours   = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      // if (hours   < 10) {hours   = "0"+hours;}
      // if (minutes < 10) {minutes = "0"+minutes;}
      // if (seconds < 10) {seconds = "0"+seconds;}
      return `${hours || 0}h ${minutes || 0}m ${seconds || 0}s`

      // const date = new Date(0)
      // date.setSeconds(Math.floor(diff)) // specify value for SECONDS here
      // const timeString = date.toISOString().substr(11, 8)
      // return timeString
    },

    put_issue(id = null) {
      if (id !== null && id !== true) {
        this.currentSelectedIssue = id
      }

      if (!this.$isEmpty(this.currentSelectedIssue)) {
        this.$http
          .put(`/api/help-desks/issues/${this.currentSelectedIssue}`, {
            form_data: this.issue
          })
          .then(response => {
            this.issue = response.data.output
          })
          .catch(() => {})
      }
    },
  }
}