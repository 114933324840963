<template>
  <div class="d-flex justify-content-start">
    <div>
      {{ firstName }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'CellRendererLink',
  computed: {
    firstName() {
      const { data } = this.params

      if (!this.$isEmpty(data.assigned_to)) {
        if (data.assigned_to === this.$store.state.auth.ActiveUser.email) {
          return 'Me'
        } else if (data.assigned_to.toLowerCase() === 'bot') {
          return 'bot'
        }
        const fullname = data.assigned_to_details.fullname
        return fullname.split(' ')[0]
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

a:hover {
  color: $primary  !important;
}
</style>