<template>
  <div class="vx-col w-full">
    <!-- FIELD: issue.subject -->
    <b-row class="mx-1 my-2">
      <h6>Issue Subject</h6>
      <b-form-row
        class="w-100 mb-1"
      >
        <b-col class="">
          <b-form-input
            v-model="issue.subject"
            class="w-100"
            :state="true"
            danger-text="Please enter a subject for the issue"
            placeholder="Title"
          />
          <b-form-invalid-feedback
            tooltip
          >
            subject error
          </b-form-invalid-feedback>
        </b-col>

      </b-form-row>
    </b-row>

    <!-- FIELD: issue.description -->
    <!-- <b-row class="mx-1 my-2">
      <h6>Description</h6>
      <b-form-row
        class="w-100 mb-2"
      >
        <b-col class="">
          <b-form-textarea
            v-model="issue.description"
            class="w-100"
            rows="2"
            placeholder="Describe your issue here"
          />
        </b-col>

      </b-form-row>
    </b-row> -->

    <!-- FIELD: issue.user_id -->
    <b-row class="mx-1 mb-2">
      <h6>Ticket Issue Owner</h6>
      <b-form-row
        class="w-100 mb-1"
      >
        <b-col class="">
          <v-select
            id="role"
            ref="role"
            v-model="issue.user_id"
            :placeholder="
              issue.user_id !== undefined
                ? issue.user_id
                : 'Pick a user'
            "
            class="selectExample w-full"
            :options="userOptions"
            :reduce="user => user.value"
            label="text"
          />

          <b-form-invalid-feedback tooltip>
            Please select a user
          </b-form-invalid-feedback>
        </b-col>
      </b-form-row>
    </b-row>

    <!-- FIELD: issue.preferred_contact_method -->
    <b-row class="mx-1 mb-2">
      <h6>Communication Method</h6>
      <b-form-row
        class="w-100 mb-1"
      >
        <b-col class="">
          <v-select
            id="role"
            ref="role"
            v-model="issue.preferred_contact_method"
            :disabled="!$isEmpty(selectedIssueId)"
            :placeholder="
              issue.preferred_contact_method !== undefined
                ? issue.preferred_contact_method
                : 'Pick a communication method'
            "
            class="selectExample w-full"
            :options="prefContactMethodOptions"
            :reduce="method => method.value"
            label="text"
          />

          <b-form-invalid-feedback tooltip>
            Please select a communication method
          </b-form-invalid-feedback>
        </b-col>
      </b-form-row>
    </b-row>

    <!-- FIELD: issue.assigned_to -->
    <b-row class="mx-1 mb-2">
      <h6>Assigned To</h6>
      <b-form-row
        class="w-100 mb-1"
      >
        <b-col class="">
          <v-select
            id="role"
            ref="role"
            v-model="issue.assigned_to"
            :placeholder="
              issue.assienged_to !== undefined
                ? issue.assienged_to
                : 'Pick a user'
            "
            class="selectExample w-full"
            :options="userOptions"
            :reduce="user => user.value"
            label="text"
          />

          <b-form-invalid-feedback tooltip>
            Please select a user
          </b-form-invalid-feedback>
        </b-col>
      </b-form-row>
    </b-row>

    <!-- FIELD: issue.attachments -->
    <b-row class="mx-1 mb-2">
      <h6>Upload a screenshot of the issue</h6>
      <b-form-row
        class="w-100 mb-1"
      >
        <b-col class="">
          <b-button
            class="ml-1 w-64 shadow-md"
            variant="warning"
            @click="$refs.uploadFile.click()"
          >Upload Screenshot</b-button>

          <input
            ref="uploadFile"
            class="hidden"
            type="file"
            accept=".jpg,.jpeg,.png,.svg"
            @change="uploadFile"
          >

          <div
            v-if="upload_url || !$isEmpty(issue.attachments)"
            class="m-1 text-center"
          >
            <b-img
              style="max-height: 18vh; width:auto;"
              class="img-fluid border border-primary"
              :src="!$isEmpty(upload_url) ? upload_url : (!$isEmpty(issue.attachments) ? issue.attachments[0] : require('@/assets/images/placeholder/no-image.jpg'))"
            />
          </div>
        </b-col>
      </b-form-row>
    </b-row>

    <!-- Buttons -->
    <div class="">
      <div class="vx-col w-full float-right">
        <b-button
          v-if="$isEmpty(selectedIssueId)"
          class="mr-3 mb-2"
          variant="primary"
          @click="saveChanges"
        >Save</b-button>
        <b-button
          v-else
          class="mr-3 mb-2"
          variant="primary"
          @click="updateChanges"
        >Edit</b-button>
      </div>
    </div>
  </div>

</template>

<script>
import VSelect from 'vue-select'

export default {
  components: {
    VSelect,
  },
  props: {
    // popupTicketForm: { type: Boolean, default: false },
    selectedIssueId: { type: String, default: null },
  },
  data() {
    return {
      issue: {},
      files: [],
      upload_url: '',

      userOptions: [],
      prefContactMethodOptions: [
        // { text: 'Chat', value: 'chat'},
        { text: 'Email', value: 'email' },
        { text: 'Telegram', value: 'telegram' },
        { text: 'Whatsapp', value: 'whatsapp' },
      ],
    }
  },
  created() {
    this.get_user_options()

    if (this.selectedIssueId) {
      this.get_issue()
    }
  },
  methods: {
    get_user_options() {
      const { company_name } = this.$store.state.auth.ActiveUser

      this.$http
        .get('/api/all/users')
        .then(response => {
          this.userOptions = []
          response.data.users.forEach(user => {
            this.userOptions.push({
              text: user.email,
              value: user.email,
            })
          })
        })
        .catch(() => {})
    },
    get_issue() {
      if (!this.$isEmpty(this.selectedIssueId)) {
        this.$http
          .get(`/api/help-desks/issues/${this.selectedIssueId}`)
          .then(response => {
            this.issue = response.data.output
          })
          .catch(() => {})
      }
    },

    saveChanges() {
      const form_data = this.issue

      const fd = new FormData()
      fd.append('subject', this.issue.subject)
      fd.append('preferred_contact_method', this.issue.preferred_contact_method)
      fd.append('user_id', this.issue.user_id)
      fd.append('assigned_to', this.issue.assigned_to)
      if (!this.$isEmpty(this.files)) {
        fd.append('files', this.files[0])
      }

      this.$http
        .post('/api/help-desks/issues', fd)
        .then(response => {
          this.$emit('close-popup', true)

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Action Success',
                icon: 'CheckCircleIcon',
                text: 'Issue Created',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {})
    },
    updateChanges() {
      const form_data = this.issue

      this.$http
        .put(`/api/help-desks/issues/${this.selectedIssueId}`, {
          form_data,
        })
        .then(response => {
          this.$emit('close-popup', true)

          this.updateFile()

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Action Success',
                icon: 'CheckCircleIcon',
                text: 'Issue Updated',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {})
    },

    uploadFile(input) {
      this.files = input.target.files

      this.upload_url = URL.createObjectURL(this.files[0])
    },
    updateFile() {
      const fd = new FormData()
      if (!this.$isEmpty(this.files)) {
        fd.append('files', this.files[0])
      }

      this.$http
        .put(`/api/help-desks/issues/${this.selectedIssueId}/update-files`, fd)
        .then(response => {
          this.$emit('close-popup', true)

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Action Success',
                icon: 'CheckCircleIcon',
                text: 'Issue Updated',
                variant: 'success',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
        .catch(error => {})
    },
  },
}
</script>

<style>

</style>
