<template>
  <div class="self-center">
    <b-badge
      pill
      :class="`bg-${chipColor(params.value)}`"
    >
      <span>{{ $capitalizeString(params.value) }}</span>
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  data() {
    return {
      status: 'open',
    }
  },
  computed: {
    chipColor() {
      return value => {
        if (value === 'open') return 'success'
        if (value === 'closed') return 'danger'
        if (value === 'urgent') return 'warning'
        return 'primary'
      }
    },
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success), 0.15);
    color: rgba(var(--vs-success), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning), 0.15);
    color: rgba(var(--vs-warning), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger), 0.15);
    color: rgba(var(--vs-danger), 1) !important;
    font-weight: 500;
  }
}
</style>
