<template>
  <div class="self-center">
    <div class="d-flex flex-row flex-wrap m-0">
      <b-button
        v-b-tooltip.hover.top="`Click here to assign ${params.data.subject} to yourself.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="$router.push(`/help-desks/issues/${params.data._id}`)"
      >
        <feather-icon
          icon="EyeIcon"
          svg-classes="hover:text-primary"
          class="text-warning"
        />
      </b-button>

      <!-- <b-button
        v-b-tooltip.hover.top="`Click here to assign ${params.data.subject} to yourself.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="confirmAssignToMe"
      >
        <feather-icon
          icon="UserIcon"
          svg-classes="hover:text-primary"
          class="text-warning"
        />
      </b-button> -->

      <!-- <b-button
        v-b-tooltip.hover.top="`Click here to edit this ${params.data.subject}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="editRecord()"
      >
        <feather-icon
          icon="EditIcon"
          svg-classes="hover:text-primary"
          class=""
        />
      </b-button> -->

      <b-button
        v-if="params.data.closed_at === null"
        v-b-tooltip.hover.top="`Click here to close ${params.data.subject}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="confirmDeleteRecord"
      >
        <feather-icon
          icon="ArchiveIcon"
          svg-classes="hover:text-danger"
          class="text-danger"
        />
      </b-button>

      <b-button
        v-else
        v-b-tooltip.hover.top="`Click here to Reopen ${params.data.subject}.`"
        class="btn-icon grid-btn"
        variant="outline-dark"
        @click="confirmReopenRecord"
      >
        <feather-icon
          icon="ArchiveIcon"
          svg-classes="hover:text-danger"
          class="text-success"
        />
      </b-button>
    </div>
  </div>
</template>

<script>

import {
  VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererActions',

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {

    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    confirmAssignToMe() {
      this.$bvModal
        .msgBoxConfirm(
          `Assign "${this.params.data.subject}" to yourself?`,
          {
            title: 'Confirm User Deletion',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Assign to Me',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.assignToMe()
          }
        })
    },
    assignToMe() {
      const form_data = this.params.data
      form_data['assigned_to'] = this.$store.state.auth.ActiveUser.email

      this.$http
        .put(`/api/help-desks/issues/${this.params.data._id}/assign`, {
          form_data,
        })
        .then(response => {
          this.params.context.componentParent.assignedToMe(this.params.data._id)
        })
        .catch(() => {})
    },

    confirmDeleteRecord() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to close "${this.params.data.subject}"`,
          {
            title: 'Confirm User Deletion',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Close',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteRecord()
          }
        })
    },
    deleteRecord() {
      this.$http
        .delete(`/api/help-desks/issues/${this.params.data._id}/close`)
        .then(response => {
          this.params.context.componentParent.get_main_data(false)
        })
        .catch(() => {})
    },

    confirmReopenRecord() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to Reopen "${this.params.data.subject}"`,
          {
            title: 'Confirm Issue Reopening',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Reopen Issue',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.reopenRecord()
          }
        })
    },
    reopenRecord() {
      this.$http
        .put(`/api/help-desks/issues/${this.params.data._id}/open`)
        .then(response => {
          this.params.context.componentParent.get_main_data(false)
        })
        .catch(() => {})
    },

    editRecord() {
      this.params.context.componentParent.editIssue(this.params.data._id)
    },
  },
}
</script>

<style type="scss" scoped>
  .grid-btn{
    margin: 2px 2px;
  }
</style>
